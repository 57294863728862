import { render, staticRenderFns } from "./CallToAction.vue?vue&type=template&id=fc471a8a&"
import script from "./CallToAction.vue?vue&type=script&lang=js&"
export * from "./CallToAction.vue?vue&type=script&lang=js&"
import style0 from "./CallToAction.vue?vue&type=style&index=0&id=fc471a8a&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/elements/Button.vue').default,Picture: require('/vercel/path0/components/elements/Picture.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
