
import { buildFileUrl } from '@sanity/asset-utils';
import SVGLogo from '../../assets/svg/logoRialto.svg';
// import SVGBackground from '../../assets/svg/lorembg.svg';
import config from '~/starter.config';

export default {
  components: {
    SVGLogo
    // SVGBackground
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      buttonUrl: '',
      buttonUrl2: ''
    };
  },
  computed: {
    isExternal() {
      return this.data.linkFile || this.data.button.isExternal;
    },
    isExternal2() {
      return this.data.linkFile2 || this.data.button2?.isExternal;
    }
  },
  async mounted() {
    if (this.data.buttonType === 'file') {
      const query = `*[ _id=="${this.data.linkFile?.asset?._ref}"][0] { ... }`;
      const docReference = await this.$sanity.fetch(query);
      this.buttonUrl = buildFileUrl(docReference, config.api);
    } else {
      this.buttonUrl = this.data.button.isExternal
        ? this.data.button.externalLink
        : this.getPageByRef(this.data?.button?.link?._ref)?.url?.pageSlug;
    }

    if (this.data.buttonType2 === 'file') {
      const query = `*[ _id=="${this.data.linkFile2?.asset?._ref}"][0] { ... }`;
      const docReference = await this.$sanity.fetch(query);
      this.buttonUrl2 = buildFileUrl(docReference, config.api);
    } else {
      this.buttonUrl2 = this.data.button2?.isExternal
        ? this.data.button2?.externalLink
        : this.getPageByRef(this.data?.button2?.link?._ref)?.url?.pageSlug;
    }
  }
};
